import React from "react"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import EventCard from "../components/event-card"
import { graphql } from "gatsby"

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const archiveTemplate = ({ data }) => {
  const events = data.allContentfulEvents.edges.map(edge => edge.node)
  return (
    <>
      <SEO title="Home" />
      <motion.section className="container mx-auto">
        <motion.div className="content">
          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 lg:grid-cols-1 gap-8"
            style={{ gridAutoRows: "1fr" }}
          >
            {events.map((event, index) => {
              return (
                <EventCard
                  key={index}
                  title={event.title}
                  image={event.image.localFile.childImageSharp.fluid}
                  imageTitle={event.image.title}
                  category={event.category}
                  target={event.eventUrl}
                  day={event.day}
                  month={event.month}
                  when={event.when}
                  presentedBy={event.presentedBy}
                  eventType={event.eventType}
                  callToAction={event.callToAction}
                  variants={item}
                  transition="easeInOut"
                  className="future"
                />
              )
            })}
          </motion.div>
        </motion.div>
      </motion.section>
    </>
  )
}

export const query = graphql`
  query upcomingEvents($date: Date) {
    allContentfulEvents(
      filter: { date: { gte: $date } }
      sort: { order: ASC, fields: date }
    ) {
      edges {
        node {
          title
          date
          day: date(formatString: "DD")
          month: date(formatString: "MMM")
          when: date(formatString: "DD MMMM - HH:mm", locale: "pt")
          presentedBy
          eventUrl
          eventType
          callToAction
          category {
            displayColor
            category
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default archiveTemplate
